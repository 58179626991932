import { ApiPath } from '@/constants/Path'
import createApiCall, { HttpMethod } from '@/helpers/createApiCall'
import { AxiosError } from 'axios'

export interface MobDataResponse {
  unit: string
  updated_at: string
  value: number
}

enum MobDataType {
  ALL = 'all',
  KAKAOT = 'kakaot',
}

const callMobDataApi = createApiCall({
  urlPrefix: '',
  presetHeaders: {
    'Content-Type': 'application/json',
  },
})

export const callMobDataDailyDistance = async () => {
  return await callMobDataApi<MobDataResponse, AxiosError>({
    ...mobDataDailyDistanceParamPreset,
  })
}

const mobDataDailyDistanceParamPreset = {
  path: ApiPath.MOB_DATA_DAILY_DISTANCE,
  method: HttpMethod.GET,
  params: {
    type: MobDataType.ALL,
  },
}

const mobDataTotalUserParamPreset = {
  path: ApiPath.MOB_DATA_TOTAL_USER,
  method: HttpMethod.GET,
  params: {
    type: MobDataType.KAKAOT,
  },
}

export const callMobDataTotalUser = async () => {
  return await callMobDataApi<MobDataResponse, AxiosError>({
    ...mobDataTotalUserParamPreset,
  })
}
