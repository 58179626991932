import { AxiosError } from 'axios'
import useSWR from 'swr'

import { callMobDataDailyDistance, MobDataResponse } from '@/apis/mobData'
import { ApiPath } from '@/constants/Path'

function useMobDataDailyDistance() {
  const { data, error, isLoading } = useSWR<MobDataResponse, AxiosError>(
    ApiPath.MOB_DATA_DAILY_DISTANCE,
    async () => (await callMobDataDailyDistance()).data
  )

  return { data, error, isLoading }
}

export default useMobDataDailyDistance
