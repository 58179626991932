import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import Grid from '@/components/Grid'
import Video from '@/components/Video'
import TransitionHandler from '@/components/TransitionHandler'
import {
  STYLE_FONT_FAMILY,
  STYLE_FONT_SIZE,
  STYLE_FONT_WEIGHT,
  STYLE_LINE_HEIGHT,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { EffectType } from '@/enums/Content'
import CountUpViewerOnlyDistance from '@/components/CountUpViewerOnlyDistance'
import useMetaContent from '@/hooks/fetch/useMetaContent'
import { Hidden } from '@kakaomobility/tui-react'
import RenderOnly from './RenderOnly'

interface DashboardVideoProps {
  play?: boolean
}

const Partial = {
  Slogan({ slogan }: { slogan: string }) {
    if (!slogan) return null

    const sloganTextList = slogan?.split(' ') ?? []
    return (
      <>
        <Hidden as='h2'>{slogan}</Hidden>
        {sloganTextList.map((text, index) => {
          const props = {
            order: index + 4,
            speed: 0.8,
          }
          return (
            <Styled.Slogan key={`DashboardVideo-slogan-${index}`} {...props}>
              {text}
            </Styled.Slogan>
          )
        })}
      </>
    )
  },
}

function DashboardVideo({
  play = false,
}: PropsWithChildren<DashboardVideoProps>) {
  const { meta } = useMetaContent()
  const pcUrl = `${process.env.JSON_DATA_EXTERNAL_URI}0-main-1.mp4`
  const mobileUrl = `${process.env.JSON_DATA_EXTERNAL_URI}0-main-1-m.mp4`
  return (
    <Styled.Wrap>
      <Styled.VideoContainer>
        <RenderOnly.Pc>
          <Styled.FullVideo src={pcUrl} play={play} />
        </RenderOnly.Pc>
        <RenderOnly.Mobile>
          <Styled.FullVideo src={mobileUrl} play={play} />
        </RenderOnly.Mobile>
      </Styled.VideoContainer>
      <Styled.Clip>
        <Styled.Corporation>&copy; {meta.corporation}</Styled.Corporation>
      </Styled.Clip>
      <Styled.Fixer stretch>
        <Styled.Column>
          <Partial.Slogan slogan={meta.slogan} />
          <Styled.Text effect={EffectType.FADE_RIGHT} order={4}>
            우리의 기술로 생활을 움직입니다.
          </Styled.Text>
        </Styled.Column>
        <Styled.Counter />
      </Styled.Fixer>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    position: relative;
    overflow: hidden;
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
  `,
  Clip: styled(RenderOnly.Pc)`
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    bottom: 0;
    clip: rect(0, auto, auto, 0);
  `,
  VideoContainer: styled.div`
    position: relative;
    height: 100vh;
    min-height: 640px;

    & > div {
      width: 100%;
      height: 100%;
    }
  `,
  FullVideo: styled(Video)`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  Fixer: styled(Grid)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  Column: styled.div`
    position: relative;
    height: 100%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      position: static;
      display: table-cell;
      height: 100vh;
      vertical-align: middle;
    }
  `,
  Text: styled(TransitionHandler)`
    position: absolute;
    top: 50%;
    margin-top: 320px;
    height: ${STYLE_LINE_HEIGHT._46};
    font-size: ${STYLE_FONT_SIZE._30};
    line-height: ${STYLE_LINE_HEIGHT._46};
    color: ${STYLE_COLOR.WHITE01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      top: auto;
      bottom: 2em;
      height: ${STYLE_LINE_HEIGHT._34};
      font-size: ${STYLE_FONT_SIZE._22};
      line-height: ${STYLE_LINE_HEIGHT._34};
    }
  `,
  Slogan: styled(TransitionHandler)`
    height: ${STYLE_LINE_HEIGHT._87};
    font-size: ${STYLE_FONT_SIZE._100};
    font-weight: ${STYLE_FONT_WEIGHT.EXTRA_BOLD};
    line-height: ${STYLE_LINE_HEIGHT._87};
    color: ${STYLE_COLOR.YELLOW};

    & + & + & {
      margin-top: 0.2em;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      height: ${STYLE_LINE_HEIGHT._52};
      font-size: ${STYLE_FONT_SIZE._60};
      line-height: ${STYLE_LINE_HEIGHT._52};
    }
  `,
  Counter: styled(CountUpViewerOnlyDistance)`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      position: absolute;
      bottom: 105px;
    }
  `,
  Corporation: styled.cite`
    position: fixed;
    right: -7.5em;
    bottom: 5em;
    font-size: ${STYLE_FONT_SIZE._12};
    color: ${STYLE_COLOR.WHITE01};
    transform: rotate(270deg);
    transform-origin: top left;
    white-space: nowrap;
  `,
}

export default DashboardVideo
